import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import Sidebar from 'components/sidebar';
import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Making The Sales Map`}</h1>
    <p><strong parentName="p">{`Day 2 -`}</strong></p>
    <p>{`If you missed either of the first two days,
`}<a parentName="p" {...{
        "href": "https://ckarchive.com/b/xmuph6hkd7r8"
      }}>{`click here for Day 0`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://ckarchive.com/b/5quvh7h3o4zw"
      }}>{`click here for Day 1`}</a>{` (we’ll link to all
editions at the bottom of each email moving forward)`}</p>
    <p><strong parentName="p">{`...and for today’s letter:`}</strong></p>
    <p>{`Good morning Blush!`}</p>
    <p>{`Yesterday - we did a "kickoff" for the project. Figuring out our goals,
anti-goals, and creating momentum in that first "1 Hour" plan.`}</p>
    <p><strong parentName="p">{`The results so far:`}</strong></p>
    <ul>
      <li parentName="ul">{`15 contacted`}</li>
      <li parentName="ul">{`5 Awaiting response (33%)`}</li>
      <li parentName="ul">{`3 Request more info (30% of responders)`}</li>
      <li parentName="ul">{`2 Passed (20% of responders)`}</li>
      <li parentName="ul"><strong parentName="li">{`5 Verbal commitments!`}</strong>{` (50% of responders) (at the minimum
$6,250 per quarter, this is $125k/yr on day 1!)`}</li>
    </ul>
    <p>{`Excellent. We already have some momentum. Today - we create our strategy.`}</p>
    <p>{`First a little lesson. My dad taught me this lesson, and I'll pass it down to
you. Some families pass down fancy watches or trust funds, but for you my dear,
you get these emails..`}</p>
    <p>{`This lesson is called `}<strong parentName="p">{`Motion, Not Direction.`}</strong></p>
    <p><strong parentName="p">{`Imagine you're on an island.`}</strong>{` But not a fun island. Some shitty island - like
in LOST, where there's a smoke monster trying to kill you. (ps. Blush, if you
ever watch Lost, skip the last season)`}</p>
    <p>{`Anyways - let's say you want to leave the bad island, and get to a great island.
`}<strong parentName="p">{`Paradise.`}</strong></p>
    <p>{`Most people will stand on the beach, squinting their eyes - looking for
paradise. But it might be far away. Or the weather might be foggy. You can't
always see paradise from where you are.`}</p>
    <p>{`This keeps most people stuck. They get paralyzed because they don't know where
to go.`}</p>
    <p>{`And to make matters worse, they don't even have a boat. They just have a few
crappy pieces of wood. It feels like they don't have enough resources to survive
in the ocean.`}</p>
    <p>{`But you Blushie - you are not going to be like "most people"!`}</p>
    <p>{`When you want to find paradise, you're going to take those two crappy pieces of
wood, and start paddling. The more you paddle, the further you can see. At some
point you will see paradise. And you'll know to start paddling towards that.
Even if you were going in the wrong direction, guess what - when you have some
momentum, it's not hard to pivot. You just make a small shift. It's much easier
than starting from a stand-still.`}</p>
    <p>{`Most people want to know what direction to go, before taking action. We are
different. We know that it's ok to have `}<strong parentName="p">{`Motion, Not Direction`}</strong>{`. Just get
moving.`}</p>
    <p>{`OK so why do I tell you this story? Because yesterday was about dreaming of
paradise. Raising a `}{`$`}{`1M+ fund in ˜4 weeks is no joke! But we didn't get
paralyzed waiting for a plan. We started taking action. We just got moving.`}</p>
    <p>{`Now that we are moving, we will start to draw up a map. A rough sketch on how we
might get to our destination.`}</p>
    <p>{`Our map is called a Sales Map. It's our One Big Thing (OBT) for today: `}<strong parentName="p">{`Create
a clear sales map that will guide our actions for the next week.`}</strong></p>
    <p>{`People think fundraising is exactly like Shark Tank. They think you need a slick
elevator pitch, and then the investor gods rain money upon thee.`}</p>
    <p>{`Sadly it is much more boring.`}</p>
    <p>{`Fundraising is a sales process. You have leads. You warm them up. Some % are
interested. Some % request additional info. Some % close.`}</p>
    <p>{`When founders ask me for fundraising advice. `}<strong parentName="p">{`What they want`}</strong>{` is pitch
feedback. But 8 times out of 10 - the reason they haven't gotten any money is
that they simply aren't talking to enough investors. They schedule 3 meetings a
week, when they should have 30.`}</p>
    <p>{`They are afraid of rejection, so they don't put themselves out there enough.`}</p>
    <p>{`I know this because I made this mistake myself. Avoiding rejection is part of
our nature. I must not make the same mistake with this fund!`}</p>
    <p>{`So what does a Sales Map look like?`}</p>
    <p>{`Here's mine. There's 2 tabs on this spreadsheet. One has dummy data that you can
use for your own projects. Anytime you need to do sales, fundraising, or
customer acquisition - this is a simple template to start with. On Tab 2 is the
sales map for this project.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://docs.google.com/spreadsheets/d/1KKp3IXFvN-eD0kRnrI777Cw3Tnyg5IM8oYXFbtiOcJs/edit?usp=sharing"
        }}>{`Here's a link to my spreadsheet for my SALES MAP (make a copy for yourself!)`}</a></strong>{`
You can use this template to make a sales map for any process you're doing
(fundraising, sales, etc.). It's really simple. But that's what we want right
now. Complexity is the enemy of execution.`}</p>
    <p><strong parentName="p">{`OK how do I think about my funnel?`}</strong><br parentName="p"></br>{`
`}{`First - I segment my leads into different categories:`}</p>
    <ul>
      <li parentName="ul">{`"Hot leads" == they've reached out, expressed interest in the past`}</li>
      <li parentName="ul">{`"Warm leads"== people who like me, and I think might be interested`}</li>
      <li parentName="ul">{`"Cold leads"== qualified people, but they don't know who I am today`}</li>
    </ul>
    <p>{`I'm going to start with the Hot & Warm leads, to build momentum and learn what
questions people have. This is a big part of fundraising. Figuring out the FAQ
(frequently asked questions), so that by the time you get to the harder to
convince folks, you already have answers to their questions ready at your
fingertips.`}</p>
    <p>{`Here's what my funnel looks like (it’s on tab 2 of the sheet linked above):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "10.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAa0lEQVQI1x2MUQrDMAxDe5tecrca7BK7ROlPnAQcR3HdtJBspvp4EkhoCSHknEths6u1zewNfJnlOBSAqhKRDyogIiklM/s9mnMuFGMD9n0jcv/c96r6Os8B1DGcqLX23p1FxIPfxRiZ2as/hbhvZLCeEYMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sales Funnel",
          "title": "Sales Funnel",
          "src": "/static/fa60613a61ba825bd308be69f05ceff4/42cbc/sales-funnel.png",
          "srcSet": ["/static/fa60613a61ba825bd308be69f05ceff4/d9f49/sales-funnel.png 400w", "/static/fa60613a61ba825bd308be69f05ceff4/78d47/sales-funnel.png 800w", "/static/fa60613a61ba825bd308be69f05ceff4/42cbc/sales-funnel.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`It's a bunch of assumptions, that give me some rough targets. After 1-2 days of
activity, I will revisit this and update it with real numbers.`}</p>
    <p>{`Next - I then set up an Airtable to track my pipeline:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmUlEQVQozz2S2XLcIBRE5/+/JpUP8Esekiq7YqdScY0nGXlsaTRoBS0sElxIC2TfBySWpk8DB2stY0xK6Yi6URLRNE2cc2OMJ8eyy+vT76EqMbuuq3P47j9a6wO6dV1rrbwPdTmEhbgQRVEopULw1Qu7/CqmbnbOyllCEEKAfol1gHPX9Qqj5Idzu3LTdm2Wvc6zDMGVp/nfoxxa8t4lQ4hdLKAlcRchIW5WoXve53luzAKP0w92//U8tJN1K2yBA5kHZAi7uKkbIYS19H5qrbbjNN5uzGgD7OO3vw9fHvTYamPGccRiKCnWjt00DSYcedYr7IkDq6oqxqP8T/7y/VlP3JHD6mSLFghIsok5F1se8rdWoQ2xEtvbU/Z891MJtloLwTzPKXZa8JlZW+evNa5qp0JhhWzPonxUwzvGYZCwE/kuBmTf9+gskcpH063xNBQZvx4X2YAZcbbn8HFgu7iuK/CA+1KJuCfFt0CBbJV3b8diNSp8VLLdMhPFzEKkq5LN7FeH7vVaYmTD7s68uFc8izL6ZE7+/wE8N3gDuoFMgwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Airtable Funnel",
          "title": "Airtable Funnel",
          "src": "/static/735ab19dc1b83a4607f3fd3f43ebbd74/42cbc/airtable-funnel.png",
          "srcSet": ["/static/735ab19dc1b83a4607f3fd3f43ebbd74/d9f49/airtable-funnel.png 400w", "/static/735ab19dc1b83a4607f3fd3f43ebbd74/78d47/airtable-funnel.png 800w", "/static/735ab19dc1b83a4607f3fd3f43ebbd74/42cbc/airtable-funnel.png 1600w"],
          "sizes": "(max-width: 1600px) 100vw, 1600px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`During the fundraising process - this table is my bible. This table is kim
kardashian's ass. My eyes are glued to this. This table is the winning lottery
numbers. This table is what I look at breakfast, lunch, and dinner.`}</p>
    <p>{`Looking at the two tables above, it's clear where the problem is. I don't have
enough leads.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "462px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "25.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABB0lEQVQY0z2PQUuEUBDH360+SLDRsU9Q3fpE0ecI9rh1WBa6yCNdBQ+uPBZlpUUhLUUxFUTFDrIVi+J7b8eV+jMMM8P8hv8gSik/ijHWHwV1WZaEENM08zyHha7r9vtf33/dbkkcv3H+w1nz9d2gJElgI4qioigAG29Bq6qqpmm6rq/XRFWJojxQet62Z30/4fyC0slncok8z3Nd98P3Hcep63p0AUOMsSzLoigqijSbPc/nd5wjCMaGXNfIez9FQRAAnGWZbdtVVf3DgiBIkgTwcvkynT4tFvcjSekA73Zg+QSB5zRN4zgOw7Bt2xGGXwzDsCxrM8jEWFmtHjm/Yez6L66a5vYAzjjyEFYeWdcAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Leads Google Sheet",
          "title": "Leads Google Sheet",
          "src": "/static/b3787e26873cd967bfd464626e3a8e1a/a9e02/leads-sheet.png",
          "srcSet": ["/static/b3787e26873cd967bfd464626e3a8e1a/d9f49/leads-sheet.png 400w", "/static/b3787e26873cd967bfd464626e3a8e1a/a9e02/leads-sheet.png 462w"],
          "sizes": "(max-width: 462px) 100vw, 462px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`When I was younger - I would panic when I found out info like this. Now I smile.
`}<strong parentName="p">{`Great - I've identified the area to focus tomorrow.`}</strong></p>
    <p>{`What a gift - knowing where to direct my energy.`}</p>
    <p>{`Tomorrow I'll be focused on coming up with strategies to generate leads for all
3 buckets!`}</p>
    <p>{`Alright - adios!`}</p>
    <p>{`-shaan`}</p>
    <Links links={[{
      title: LinkLabel.Template,
      links: [{
        url: 'https://docs.google.com/spreadsheets/d/1KKp3IXFvN-eD0kRnrI777Cw3Tnyg5IM8oYXFbtiOcJs/edit?usp=sharing',
        label: 'Sales Map Google Sheet'
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.project_kickoff.label,
      slug: slugify(postMap.project_kickoff.slug)
    }} nextButton={{
      text: postMap.nailing_our_pitch.label,
      slug: slugify(postMap.nailing_our_pitch.slug)
    }} mdxType="PostFooter" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      